import { Link } from 'gatsby';
import { createUseStyles } from 'react-jss';
import React from 'react';
import Card from '../components/Card';
import Divider from '../components/Divider';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const useStyles = createUseStyles(theme => ({
  main: {
    
  },
  featureCardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  featureCardContent: {
    lineHeight: '1.5',
    '& li': {
      marginBottom: '1rem',
    },
    padding: '1rem',
  },
  featureCardTitle: {
    color: 'white',
    fontWeight: 'normal',
    fontSize: '2rem',
    margin: '0',
    background: '#009688',
    padding: '1rem',
    textAlign: 'center',
  },
  featureContainer: {
    margin: '2rem',
  },
  header: {
    fontSize: theme.fontSizeLarge,
    fontWeight: '400',
    margin: '2rem',
    textAlign: 'center',
  },
  headerSubtitle: {
    color: theme.colorPrimary,
    fontSize: theme.fontSizeLarge,
    fontWeight: '400',
    margin: '2rem',
    textAlign: 'center',
  },
  jumpTo: {
    fontSize: theme.fontSizeMedium,
    marginTop: '1rem',
    textAlign: 'center',
  },
  quickLink: {
    fontSize: theme.fontSizeMedium,
    margin: '1rem',
  },
  quickLinkContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    padding: '1.5rem',
  },
}));

const styleFeatureCard = {
  boxSizing: 'border-box',
  flex: '0 1 550px',
  padding: '0',
};

const Features = (props) => {
  const classes = useStyles(props);
  return (
    <Layout>
      <SEO title="Features" />
      <div className={classes.jumpTo}>
        Jump To
      </div>
      <div className={classes.quickLinkContainer}>
        <Link className={classes.quickLink} to="/features#prep">
          Prep &amp; Inventory
        </Link>
        <Link className={classes.quickLink} to="/features#checkouts">
          Checkouts
        </Link>
        <Link className={classes.quickLink} to="/features#staff">
          Staff Management &amp; HR Tools
        </Link>
        <Link className={classes.quickLink} to="/features#extras">
          Extras
        </Link>
      </div>
      <Divider />
      <div className={classes.main}>
        <div className={classes.featureContainer}>
          <h1 className={classes.header} id="prep">
            Prep &amp; Inventory
          </h1>
          <div className={classes.headerSubtitle}>
            Quickly digitize prep lists and recipes.
            Ensures consistency and efficiency for daily food prep
          </div>
          <div className={classes.featureCardContainer}>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Inventory Counting
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Painless count with
                    {' '}
                    <b>
                      customizable units
                    </b>
                    {' '}
                    and
                    {' '}
                    <b>
                      automatic unit conversion
                    </b>
                  </li>
                  <li>
                    Inventory checklist with
                    {' '}
                    <b>
                      multiple storage locations
                    </b>
                  </li>
                  <li>
                    Compare staff speed with
                    {' '}
                    <b>
                      count timers
                    </b>
                  </li>
                  <li>
                    Immediately log waste while counting inventory
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Food Prep
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    <b>
                      Customizable daily
                    </b>
                    {' '}
                    demand level (Slow, Medium, Busy, Holiday)
                  </li>
                  <li>
                    Never run out, set a
                    {' '}
                    <b>
                      minimum quantity
                    </b>
                    {' '}
                    to have on hand
                  </li>
                  <li>
                    <b>
                      Automatically adjusts pars
                    </b>
                    {' '}
                    based off historical data*
                  </li>
                  <li>
                    Projects
                    {' '}
                    <b>
                      future usage
                    </b>
                    {' '}
                    based off shelf life, time to prep and future demand. Never run out again!
                  </li>
                  <li>
                    Know the
                    {' '}
                    <b>
                      exact amount to make
                    </b>
                    , taking into account inventory, demand, recipe and projected usage
                  </li>
                  <li>
                    Records quantity made, staff, time and recipe used
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Recipes
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Suggests the perfect recipe to achieve par, while
                    {' '}
                    <b>
                      maximizing efficiency
                    </b>
                  </li>
                  <li>
                    Accurately
                    {' '}
                    <b>
                      autoscales
                    </b>
                    {' '}
                    recipes to prevent mistakes
                  </li>
                  <li>
                    <b>
                      Groups recipes
                    </b>
                    {' '}
                    that have common ingredients like making dough or chopping veggies*
                  </li>
                  <li>
                    Built in
                    {' '}
                    <b>
                      kitchen timers*
                    </b>
                  </li>
                  <li>
                    Compare prep times between staff*
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Waste Tracking
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Tracks all your waste in one spot
                  </li>
                  <li>
                    Logs when and
                    {' '}
                    <b>
                      why
                    </b>
                    {' '}
                    something is discarded
                  </li>
                  <li>
                    Get instant email or mobile
                    {' '}
                    <b>
                      alerts
                    </b>
                    {' '}
                    for waste on big ticket items
                  </li>
                  <li>
                    <b>
                      Customizable
                    </b>
                    {' '}
                    waste tags
                  </li>
                </ul>
              </div>
            </Card>
          </div>
        </div>
        <Divider />
        <div className={classes.featureContainer}>
          <h1 className={classes.header} id="checkouts">
            Checkouts
          </h1>
          <div className={classes.headerSubtitle}>
            Track cash and ensure accountability every step of the way from counter to bank
          </div>
          <div className={classes.featureCardContainer}>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Staff Checkout
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Waste less time dealing with cash
                  </li>
                  <li>
                    Works with cash drawers, individual’s banks, or your own
                    {' '}
                    <b>
                      custom
                    </b>
                    {' '}
                    set up
                  </li>
                  <li>
                    Fully
                    {' '}
                    <b>
                      customizable
                    </b>
                    {' '}
                    to fit any business
                  </li>
                  <li>
                    Automatically calculates how much
                    {' '}
                    <b>
                      cash is owed, charged tips and drawer shortages
                    </b>
                  </li>
                  <li>
                    Integrates with printers to provide
                    {' '}
                    <b>
                      deposit slips
                    </b>
                    {' '}
                    for every drop
                  </li>
                  <li>
                    Cash drops can be recounted, logged and
                    {' '}
                    <b>
                      verified by management
                    </b>
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Tip Out
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Calculate tip out amounts for
                    {' '}
                    <b>
                      individual departments
                    </b>
                  </li>
                  <li>
                    Tip out departments based on
                    {' '}
                    <b>
                      customizable numbers
                    </b>
                    {' '}
                    like food sales, net sales, and alcohol sales
                  </li>
                  <li>
                    Allows staff to easily
                    {' '}
                    <b>
                      modify tip out amounts
                    </b>
                    {' '}
                    based on peer work performance
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Cash Drawer Count
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Staff can
                    {' '}
                    <b>
                      quickly
                    </b>
                    {' '}
                    record accurate drawer opening and closing counts
                  </li>
                  <li>
                    Re-counts are logged. See if
                    {' '}
                    <b>
                      money disappears
                    </b>
                    {' '}
                    “magically” after the first count
                  </li>
                  <li>
                    Hold staff individually
                    {' '}
                    <b>
                      responsible
                    </b>
                    {' '}
                    for their assigned drawer
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Closeout
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Checkouts can be grouped into a closeout by management for
                    {' '}
                    <b>
                      easy accounting
                    </b>
                  </li>
                  <li>
                    Closeout drop amounts can be recounted, logged and
                    {' '}
                    <b>
                      verified by owner
                    </b>
                  </li>
                </ul>
              </div>
            </Card>
          </div>
        </div>
        <Divider />
        <div className={classes.featureContainer}>
          <h1 className={classes.header} id="staff">
            Staff Management &amp; HR Tools
          </h1>
          <div className={classes.headerSubtitle}>
            KounterTop enables timely and effective communication between staff and management
          </div>
          <div className={classes.featureCardContainer}>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Feedback and Communication
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Enables a
                    {' '}
                    <b>
                      full dialog
                    </b>
                    {' '}
                    between staff and management
                  </li>
                  <li>
                    Get immediate customer feedback
                  </li>
                  <li>
                    Use tags to categorize and
                    {' '}
                    <b>
                      prioritize
                    </b>
                    {' '}
                    pressing issues
                  </li>
                  <li>
                    Automatically include relevant staff in conversation based on tag
                  </li>
                  <li>
                    Receive instant email and mobile
                    {' '}
                    <b>
                      notifications
                    </b>
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Staff Notes
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Log notes on specific staff
                    {' '}
                    <b>
                      performance
                    </b>
                  </li>
                  <li>
                    Use tags to group notes like
                    {' '}
                    <b>
                      punctuality, complaints, compliments, and staff interactions
                    </b>
                  </li>
                  <li>
                    Instant email and mobile
                    {' '}
                    <b>
                      notifications
                    </b>
                    {' '}
                    when management adds a note
                  </li>
                  <li>
                    Makes
                    {' '}
                    <b>
                      performance reviews
                    </b>
                    {' '}
                    easier through compiling data on staff activities
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Manager Notes
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Keep track of
                    {' '}
                    <b>
                      goals, performance and maintenance
                    </b>
                  </li>
                  <li>
                    Keep a
                    {' '}
                    <b>
                      daily log
                    </b>
                    {' '}
                    of activities, sales, and staff levels
                  </li>
                  <li>
                    Look back at
                    {' '}
                    <b>
                      last year’s
                    </b>
                    {' '}
                    notes to prepare and improve performance
                  </li>
                  <li>
                    Stay
                    {' '}
                    <b>
                      reminded of the small things
                    </b>
                    {' '}
                    that add up to a big difference
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Announcements
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Display
                    {' '}
                    <b>
                      announcement banners
                    </b>
                    {' '}
                    for all staff to see
                  </li>
                  <li>
                    Keep everyone up to date on
                    {' '}
                    <b>
                      daily specials
                    </b>
                    {' '}
                    and large reservations
                  </li>
                  <li>
                    Address recurring issues with everyone instantly. For example utensils
                    being accidentally tossed
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Calendar
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Manage
                    {' '}
                    <b>
                      events
                    </b>
                    {' '}
                    and daily demand levels
                  </li>
                  <li>
                    Prepare for
                    {' '}
                    <b>
                      holidays
                    </b>
                    {' '}
                    and local events
                  </li>
                </ul>
              </div>
            </Card>
          </div>
        </div>
        <Divider />
        <div className={classes.featureContainer}>
          <h1 className={classes.header} id="extras">
            Extras
          </h1>
          <div className={classes.headerSubtitle}>
            
          </div>
          <div className={classes.featureCardContainer}>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Full Edit History and Accountability
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    All data and items have
                    {' '}
                    <b>
                      revision history
                    </b>
                    . Go back and see all edits since creation
                  </li>
                  <li>
                    Undo an
                    {' '}
                    <b>
                      accidental deletion
                    </b>
                    {' '}
                    or change
                  </li>
                  <li>
                    All
                    {' '}
                    <b>
                      changes are logged
                    </b>
                    {' '}
                    with a staff name and timestamp
                  </li>
                  <li>
                    No more guessing who made changes
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Reporting
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    <b>
                      Powerful reporting
                    </b>
                    {' '}
                    engine to view data in any way
                  </li>
                  <li>
                    Run
                    {' '}
                    <b>
                      customizable reports
                    </b>
                    {' '}
                    any time, anywhere
                  </li>
                  <li>
                    Built in powerful reports get you going with
                    {' '}
                    <b>
                      no extra work
                    </b>
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Permissions
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    <b>
                      Full control
                    </b>
                    {' '}
                    over individual staff access
                  </li>
                  <li>
                    Create
                    {' '}
                    <b>
                      read-only accounts
                    </b>
                    {' '}
                    to prevent accidental data edits
                  </li>
                  <li>
                    Give access to only the necessary features
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Security
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    Security is a
                    {' '}
                    <b>
                      top priority
                    </b>
                    {' '}
                    for KounterTop
                  </li>
                  <li>
                    All traffic is encrypted so
                    {' '}
                    <b>
                      only you
                    </b>
                    {' '}
                    can see your data
                  </li>
                  <li>
                    KounterTop uses
                    {' '}
                    <b>
                      industry leading security
                    </b>
                    {' '}
                    practices to keep all data safe
                  </li>
                </ul>
              </div>
            </Card>
            <Card style={styleFeatureCard}>
              <h5 className={classes.featureCardTitle}>
                Misc
              </h5>
              <div className={classes.featureCardContent}>
                <ul>
                  <li>
                    <b>
                      Automatic Updates:
                    </b>
                    {' '}
                    App includes seamless updates for the newest features,
                    bug fixes, and security patches
                  </li>
                  <li>
                    <b>
                      Multi-Location:
                    </b>
                    {' '}
                    Easily switch between managing multiple locations with one login
                  </li>
                  <li>
                    <b>
                      No New Hardware:
                    </b>
                    {' '}
                    Use existing computers, tablets or staff’s personal devices.
                    Supports iPhone, iPad, Samsung, Android, Windows, Mac, and many more
                  </li>
                  <li>
                    <b>
                      Data Import:
                    </b>
                    {' '}
                    KounterTop can import data from many popular apps, POSs and spreadsheets
                  </li>
                </ul>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Features;
